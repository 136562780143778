/** @jsx jsx */
import { jsx } from "theme-ui";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import FloatingBridgeGif from "./FloatingBridgeGif";

const ProjectImg = ({ filename, alt }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) =>
        n.node.relativePath.includes(filename)
      );

      if (!image) {
        return <FloatingBridgeGif src={filename} />;
      }

      const imageFluid = image.node.childImageSharp.gatsbyImageData;

      return (
        <div
          sx={{
            boxShadow: `lg`,
          }}
        >
          <GatsbyImage
            image={imageFluid}
            alt={alt}
            sx={{
              borderTopLeftRadius: `lg`,
              borderTopRightRadius: `lg`,
              WebkitMaskImage: `-webkit-radial-gradient(white, black)`,
            }}
          />
        </div>
      );
    }}
  />
);

ProjectImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default ProjectImg;
