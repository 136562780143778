/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fade } from "react-awesome-reveal";

import ProjectImage from "../../../components/Image/ProjectImage";
import { bounce } from "../../../styles/animations";
import { useDesktop } from "../../../hooks";

type ProjectCardProps = {
  link: string;
  title: string;
  subtitle: string;
  filename: string;
  children: React.ReactNode;
  bg?: string;
};

const Card = ({
  link,
  title,
  subtitle,
  filename,
  children,
}: ProjectCardProps) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      sx={{
        color: `white`,
        "&:hover": {
          color: `white`,
        },
      }}
    >
      <div
        sx={{
          maskImage: `linear-gradient(to bottom, black 50%, transparent 100%)`,
        }}
      >
        <ProjectImage filename={filename} alt={title} />
      </div>
      <div
        sx={{
          px: 4,
          pb: [4, 5],
          textDecoration: `none`,
        }}
      >
        <div
          sx={{
            textTransform: `uppercase`,
            letterSpacing: `wide`,
            pb: 2,
            fontSize: [4, 5],
            fontWeight: `semibold`,
            lineHeight: 1,
          }}
        >
          {title}
        </div>
        <div
          sx={{
            letterSpacing: `wide`,
            pb: 4,
            fontSize: [2, 3],
            lineHeight: 1,
          }}
        >
          {subtitle}
        </div>
        <div
          sx={{
            textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
            fontWeight: "medium",
          }}
        >
          {children}
        </div>
      </div>
    </a>
  );
};

const ProjectCard = ({
  link,
  title,
  subtitle,
  filename,
  children,
  bg,
}: ProjectCardProps) => {
  const isDesktop = useDesktop();

  return (
    <div
      sx={{
        "&:hover": {
          animation: `${bounce(2)} 2s infinite`,
        },
      }}
    >
      <Fade
        triggerOnce
        delay={200}
        direction={isDesktop ? "left" : "up"}
        sx={{
          boxShadow: `lg`,
          borderRadius: `lg`,
          background: bg || `none`,
          transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
          "&:hover": {
            boxShadow: `xl`,
          },
          height: "100%",
        }}
      >
        <Card
          link={link}
          title={title}
          subtitle={subtitle}
          filename={filename}
          children={children}
        />
      </Fade>
    </div>
  );
};

export default ProjectCard;
