/** @jsx jsx */
import { jsx } from "theme-ui";

export default function FloatingBridgeGif({ src }) {
  return (
    <div
      sx={{
        boxShadow: `lg`,
      }}
    >
      <video
        src={src}
        width="100%"
        autoPlay
        loop
        muted
        sx={{
          borderTopLeftRadius: `lg`,
          borderTopRightRadius: `lg`,
          WebkitMaskImage: `-webkit-radial-gradient(white, black)`,
        }}
      />
    </div>
  );
}
