import * as React from "react";
import ProjectCard from "../components/project-card";
import ExternalLink from "../../../components/ExternalLink";

const components = {
  ProjectCard: ({ link, title, subtitle, bg, children, ...props }) => (
    <ProjectCard
      {...props}
      link={link}
      title={title}
      subtitle={subtitle}
      bg={bg}
    >
      {children}
    </ProjectCard>
  ),
  ExternalLink: ({ children, href, ...props }) => (
    <ExternalLink href={href} {...props}>
      {children}
    </ExternalLink>
  ),
};

export default components;
