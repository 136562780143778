import { keyframes } from "@emotion/react";

export const bounce = (y: number) =>
  keyframes`
      0%, 100% {
        transform: translateY(0);
        animationTimingFunction: cubic-bezier(0.1, 0, 1, 1);
      }
      50% {
        transform: translateY(-${y}%);
        animationTimingFunction: cubic-bezier(0, 0, 0.1, 1);
      }
    `;
